import React from 'react';
// import { navigate } from '@reach/router';
// import { Link, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
// import LocaleContext from '../context/Locale';

// Styled Components
// const Par = styled.p`
//   text-align: center;
//   font-weight: bold;
// `;
// const ParHead = styled.p`
//   text-align: center;
//   font-weight: bold;
// `;

// Main Component
function IndexPage() {
  return (
    <Layout shop={true}>
      <div
        className="px-6 pt-20 pb-20 container mx-auto w-full block pt-6 text-white"
        style={{ maxWidth: '700px' }}
      >
        <h1 className="text-center text-xl">Polityka prywatności</h1>
        <p>
          Zapewniamy naszym Klientom pełne poszanowanie ich prywatności oraz
          ochronę ich danych osobowych.
        </p>
        <p>&nbsp;</p> 
        <p>
          Dane osobowe przekazywane nam przez Klientów przetwarzamy w sposób
          zgodny z zakresem udzielonego przez Klientów zezwolenia oraz wymogami
          prawa. Dane osobowe Klientów przechowujemy na serwerach zapewniających
          ich pełne bezpieczeństwo. Dostęp do bazy danych posiadają jedynie
          uprawnieni pracownicy Sklepu.
        </p>
        <p>&nbsp;</p>
        <p>
          Administratorem danych osobowych Użytkownika Sklepu jest firma „Kulka”
          Łukasz Kulka reprezentująca zespół POTOCK. Administrator przetwarza
          dane osobowe Użytkowników Sklepu zgodnie z Ogólnym Rozporządzeniem o
          Ochronie Danych z dnia 27 kwietnia 2016 r. („RODO”).
        </p>
        <p>&nbsp;</p>
        <p>
          Dokonując zakupów w Sklepie Użytkownik podaje określone dane osobowe,
          niezbędne do wykonania umowy sprzedaży. Odmowa podania danych
          koniecznych do realizacji zamówienia skutkuje brakiem możliwości
          wykonania przez Sklep umowy sprzedaży. Nie jest obowiązkowe wyrażenie
          przez Użytkownika zgody na otrzymywanie informacji handlowej na podany
          adres e-mail lub podany nr telefonu dla realizacji zawartej umowy
          sprzedaży Towarów. Jeżeli zgoda zostanie wyrażona, będzie mogła być
          cofnięta w każdym czasie. Cofnięcie zgody nie wpływa na zgodność z
          prawem przetwarzania sprzed cofnięcia.
        </p>
        <p>&nbsp;</p>
        <p>
          Każdy Klient, który przekazał nam swoje dane osobowe ma pełną
          możliwość dostępu do swoich danych w celu ich weryfikacji, modyfikacji
          lub też żądania usunięcia.
        </p>
        <p>&nbsp;</p>
        <p>
          Sklep nie przekazuje, nie sprzedaje ani nie użycza zgromadzonych
          danych osobowych Klientów osobom trzecim, chyba że dzieje się to za
          wyraźną zgodą lub na życzenie Klienta albo na żądanie uprawnionych na
          podstawie prawa organów państwa w związku z toczącymi się
          postępowaniami.
        </p>
        <p>&nbsp;</p>
        <p>
          Nasz Sklep posługuje się również tzw. plikami cookies (ciasteczka).
          Pliki te są zapisywane na komputerze Klienta przez nasz serwer i
          dostarczają danych statystycznych o aktywności Klienta, w celu
          dobrania naszej oferty do jego indywidualnych potrzeb i gustów. Klient
          w każdej chwili może wyłączyć w swojej przeglądarce internetowej opcję
          przyjmowania cookies, choć musi mieć świadomość, że w niektórych
          przypadkach odłączenie tych plików może wpłynąć na utrudnienia w
          korzystaniu z oferty naszego Sklepu. Pliki cookies zapisywane na
          komputerze Klienta przechowują informacje na temat:
        </p>
        <p>&nbsp;</p>
        <ul className="list-disc pl-6">
          <li>sesji Użytkownika,</li>
          <li>ostatnio oglądanych produktów </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Pragniemy zwrócić Państwa uwagę, że jeżeli na stronie internetowej
          naszego Sklepu zamieszczamy linki prowadzące do innych,
          nieadministrowanych przez nas stron internetowych, to nie możemy
          odpowiadać ani za zawartość tychże stron, ani za stopień ochrony
          prywatności realizowany przez administratorów tych stron. Podejmując
          decyzję o przejściu na takie strony, Klient czyni to na własną
          odpowiedzialność. Zachęcamy przy okazji do zapoznania się z polityką
          prywatności realizowaną przez te strony, zanim Klient udostępni im
          swoje dane osobowe.
        </p>
        <p>&nbsp;</p>
        <p>
          Materiały reklamowo - promocyjne wysyłamy Klientom jedynie wówczas,
          gdy wyrazili na to zgodę. Dotyczy to w szczególności newslettera
          wysyłanego Klientowi, jeżeli potwierdzi wyraźnie wskazanego przez nas
          linka zawierającego akceptację na otrzymywanie newslettera o
          aktualnych promocjach,  rabatach i nowościach w naszym Sklepie.
          Materiały te dotyczą  wyłącznie oferty naszego Sklepu.
        </p>
        <p>&nbsp;</p>
        <p>
          Wszelkie pytania, wnioski i sugestie odnoszące się do ochrony Państwa
          prywatności, w szczególności danych osobowych prosimy zgłaszać na
          adres bądź telefonicznie pod numerem +48 504 138 656
        </p>
      </div>
    </Layout>
  );
}

export default IndexPage;
